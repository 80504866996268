<template>
  <Layout>
    <template #default>
      <div>
        <div class="container-fluid m-0 p-0">
          <div class="div bgFoto" >
            <div class="row justify-content-center">
              <div class="col-8 text-center headline">
                <span>Software <br />für kleine und mittelständige Unternehmen</span>
              </div>
            </div>
            <div class="row justify-content-center einblenden1 pt-5">
              <div class="col-7 DES bildschirnansicht">
                <h1>DEVELOPMENT / ENGINEERING / SUPPORT</h1>
              </div>
              <div class="col-7 DES handyansicht">
                <h1>DEVELOPMENT</h1>
                <h1>ENGINEERING</h1>
                <h1>SUPPORT</h1>
                <h1>WEBDESIGN</h1>
                <h1>WEBSHOPS</h1>
              </div>
            </div>
            <div class="row justify-content-center pb-5 einblenden1 bildschirnansicht">
              <div class="col-5 DES">
                <h1>WEBDESIGN / WEBSHOPS</h1>
              </div>
            </div>
          </div>
          <div class="hintergrundHell">

            <div class="row pt-5 justify-content-center">
              <div class="col-12 pt-5 t-center">
                <span class="Abschnitt-titel2">QDS</span>
              </div>
            </div>
            <div class="row pt-5 justify-content-center">
              <div class="col-4 t-center p-4">
                <hr />
              </div>
            </div>
         
            <div class="row justify-content-center">
              <div class="col-7 t-center">
                <img src="../assets/qds.jpg" class="img-fluid mx-auto d-block" />
              </div>
            </div>

            <div class="row pt-5 justify-content-center">
              <div class="col-12 t-center p-0">
                <h2>
                  Dokumentation von Prüf- und Testergebnissen in der Kleinserien- und Musterproduktion
                </h2>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-6 col-sm-10 p-4 t-center">
                <p>
                  Erstellen Sie einfach Prüfabläufe für ihre Qualitätssicherung und Dokumentation
                </p>
                <p>
                  Für jedes Modell in Ihrer Fertigung können eigene Szenarien  angelegt werden
                </p>
                <p>
                  Führen Sie Prüfungen nach festgelegter Häufigkeit durch
                  <ul>
                    <li>jedesmal bei einem Arbeitsschritt</li>
                    <li>Regelmäßig alle x Fertigungen</li>
                    <li>
                      Zufällig alle x Fertigungen
                    </li>                    
                  </ul>
                </p>
              </div>
            </div>
            <div class="row justify-content-center"
            v-if="windowHeight > 800">
              <div class="col-6 text-center">
                {{ windowWight }}
                <video controls width="600" class="videoClassGross videosize">
                  <source src="../assets/QDS.mp4" type="video/mp4" />
                  Sorry, your browser doesn't support embedded videos.
                </video>
              </div>
            </div>
            <div class="row pt-5 justify-content-center">
              <div class="col-12 t-center p-0">
                <h2>
                  Natürlich ist QDS auf Ihre Bedürfnisse anpassbar
                </h2>
              </div>
            </div>
            <div class="row t-center">
              <div class="col-12 p-4">
                <button
                class="btn btn-outline-danger"
                 @click="$router.push('/QDS')">Mehr zu QDS</button>
              </div>
            </div>



          </div>
          <div class="hintergrundSchwarz">
            <div class="row pt-5 justify-content-center">
              <div class="col-7 pt-5 t-center">
                <span class="Abschnitt-titel">Webdesign</span>
              </div>
            </div>
            <div class="row p-3 justify-content-center">
              <div class="col-4 t-center p-4">
                <hr />
              </div>
            </div>
           
            <div class="row justify-content-center">
              <div class="col-7 t-center">
                <h2>WIR helfen Ihnen!</h2>
              </div>
            </div>

            <div class="row pt-5 justify-content-center">
              <div class="col-10 t-center p-0">
                <h2>
                  Wenn Ihr Webauftritt veraltet ist und nicht auf mobilen Geräten
                  funktioniert.
                </h2>
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-md-6 col-sm-10 p-4 t-center">
                <p>
                  Wie in der Mode, ändert sich der Geschmack mit den Jahren. Auch technisch
                  geht die Entwicklung weiter. Neue Designmöglichkeiten stehen zur
                  Verfügung und die vorhandene Webseite ist nicht mehr dem aktuellen
                  Zeitgeist entsprechend.
                </p>
                <p>
                  Zu einem modernen Unternehmen, wie dem Ihren, gehört auch immer eine
                  moderne Webseite.
                </p>
                <p>
                  Daher sollten Sie spätestens alle 4-5 Jahre sich ihre Webseite einmal
                  kritisch betrachten. Entspricht das, was Sie sehen, noch dem, was Sie in
                  der Öffentlichkeit repräsentieren möchten?
                </p>
                <p>
                  <span class="psText">PILLARSOFT</span> hilft Ihnen Ihre Webseite wieder
                  auf den aktuellen Stand der Technik und zu einem modernen Design zu
                  bringen.
                </p>
                <p>Helfen Sie ihren Kunden mit Ihnen in Verbindung zu treten.</p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-4 col-sm-8 t-center">
                <ul>
                  <li>Aktuelle Telefonnummern</li>
                  <li>Aktuelle Mailadressen</li>
                  <li>Kontaktformulare</li>
                  <li>Impressum</li>
                  <li>Datenschutz</li>
                </ul>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-8 t-center">
                <p>
                  Dies sind typische Dinge, welche sich mit den Jahren ändern und gerade bei
                  rechtlichen Dingen richtig teuer werden können, wenn sie falsch sind.
                </p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-12 p-4 t-center">
                <button
                class="btn btn-outline-danger"
                 @click="$router.push('/Software')">Webdesign</button>
              </div>
            </div>
          </div>

          <div class="hintergrundHell">

            <div class="row pt-5 justify-content-center">
              <div class="col-12 pt-5 t-center">
                <span class="Abschnitt-titel2"
                v-if="windowWight > 800">Softwarelösungen</span>
                
                <span class="Abschnitt-titel2-Handy"
                v-if="windowWight < 801">Softwarelösungen</span>
                
              </div>
            </div>
            <div class="row pt-5 justify-content-center">
              <div class="col-4 t-center p-4">
                <hr />
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-7 t-center">
                <h2>WIR helfen Ihnen!</h2>
              </div>
            </div>

            <div class="row pt-5 justify-content-center">
              <div class="col-12 t-center p-0">
                <h2>
                  Wenn Sie ein Problem lösen wollen, aber keine passende
                  Software finden.
                </h2>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-6 col-sm-10 p-4 t-center">
                <p>
                  Software gibt es wie Sand am Meer. Die meisten Programme versuchen möglichst alle Probleme auf einmal zu lösen.
                  Das führt in den meisten Fällen dazu, dass die Programme völlig überladen, unübersichtlich und nur bedingt zu gebrauchen sind.                  
                </p>
                <p>
                  Genauso wie Ihr Unternehmen einzig artig ist, sind auch die Anforderungen an Ihre Software einzigartig. 
                </p>
                <p>
                  <span class="psText">PILLARSOFT</span> erstellt Ihnen die Software, welche Sie für die optimale Organisation Ihres Unternehmens brauchen.
                </p>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-4 col-sm-10 t-center">
                <p>
                  <ul>
                    <li>Verknüpfen Sie Ihre Webseite mit Ihrer internen Kommunikation.</li>
                    <li>Stellen Sie Ihren Kunden individuelle Informationen zur Verfügung.</li>
                    <li>
                      Reduzieren Sie den Papierkrieg in Ihren Büro auf das nötigste.
                    </li>
                    <li>
                      Moderne Dokumentenablagesysteme helfen Ihnen Zeit und Geld zu sparen.
                    </li>
                  </ul>
                   
                </p>
              </div>
            </div>
            <div class="row t-center">
              <div class="col-12 p-4">
                <button
                class="btn btn-outline-danger"
                 @click="$router.push('/Software')">Softwareentwicklung</button>
              </div>
            </div>



          </div>



          <div class="hintergrundSchwarz">
            <div class="row pt-5 justify-content-center">
              <div class="col-7 pt-5 t-center">
                <span class="Abschnitt-titel">Webshops</span>
              </div>
            </div>
            <div class="row pt-5 justify-content-center">
              <div class="col-4 t-center p-4">
                <hr />
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-7 t-center">
                <h2>WIR helfen Ihnen!</h2>
              </div>
            </div>

            <div class="row pt-5 justify-content-center">
              <div class="col-11 t-center p-0">
                <h2>
                  Wenn Sie Ihre tollen Produkte in einem Webshop verkaufen
                  möchten.
                </h2>
              </div>
            </div>
            <div class="row justify-content-center">
              <div class="col-md-6 col-sm-10 p-4 t-center">
                <p>
                  Webshops sind heutzutage die häufigste Visualisierungsform von Marken.
                </p>
                <p>
                  Eine schlechte, veraltete oder irreführende Website enttäuscht daher nicht nur Ihre Besucher.
                  Sie richtet auch einen großen Imageschaden an und führt dazu, dass Ihr Geschäftserfolg hinter seinen Möglichkeiten zurückbleibt.
                  </p>
                  <p>
                  Es gibt mittlerweile mehr Mobile Geräte, wie Handys, Smartphones und Tablets, als PC's und Notebooks.
                  Aber nur auf neueren Webseiten ist die Darstellung mit diesen Geräten möglich.
                </p>
              </div>
            </div>
            <div class="row t-center">
              <div class="col-12 p-4">             
                  <button
                      class="btn btn-outline-danger"
                       @click="$router.push('/Shop')">Onlineshop</button>
              </div>
            </div>
          </div>

          <div class="hintergrundHell pb-5">

            <div class="row pt-5 justify-content-center">
              <div class="col-7 pt-5 t-center">
                <span class="Abschnitt-titel2">Wir bieten</span>
              </div>
            </div>
            <div class="row pt-5 justify-content-center">
              <div class="col-4 t-center p-4">
                <hr />
              </div>
            </div>

            <div class="row justify-content-center">
              <div class="col-10 t-center">
                <h2>Welche Dienstleistungen bietet PILLARSOFT?</h2>
              </div>
            </div>

            <div class="row pt-5 pb-5 justify-content-center">
              <div class="col-10 t-center p-0">
                <h2>
                  Unser Unternehmen hat seinen Standort im schönen Thüringen.
                </h2>
              </div>
            </div>

            <div class="row justify-content-center pb-5">
              <div class="col-md-5 col-sm-10 t-center">
                <h3>  Unsere Leistungen umfassen folgende Gebiete:
                </h3>
                  <ul>
                    <li>
                      Individuelle Beratung, Konzeption und Projektierung persönlich bei Ihnen Vorort
                    </li>
                    <li>
                      User Interface Designs und moderne Gestaltung
                    </li>
                    <li>
                      Installation und Systemkonfiguration auf Ihrer Systemumgebung
                    </li>
                    <li>
                      Umsetzung und Entwicklung von individuellen Software-Bundles
                    </li>
                    <li>
                      Schnittstellenentwicklung
                    </li>
                    <li>
                      Automatisiertes und manuelles Testing, Betrieb & Support
                    </li>
                    <li>
                      Einführung einer ständigen Qualitätssicherung
                    </li>
                    <li>
                      Kontinuierliche Weiterentwicklung
                    </li>
                  </ul>
                  
              </div>
            </div>
            

          </div>
          <div class="div bgmain2 bildschirnansicht" :style="'min-height: ' + windowHeight *1.1 + 'px;'" >
            <div class="row p-5 justify-content-center">
              <div class="col-3 cardCenter  p-4">
                <div class="card pt-3 t-center hintergrundHell" style="width: 22rem;">
                  
                  <div class="card-body p-4">
                    <p class="cardHeader">Webdesign</p>
                    <p class="card-text pt-5"><span class="psText">PILLARSOFT</span> <br/>erstellt  Ihnen Ihre Webseite 
                      auf den aktuellen Stand der Technik und in modernem Design. <br/> <br/> Direkt mit Ihnen zusammen</p>
                    <p class=" pt-2" >
                      <a
                href="#WEBDESIGN"
                class="btn btn-outline-danger"
                tabindex="-1"
                role="button"
                aria-disabled="true"
                >Webdesign</a
              >
                    </p>
                  </div>
                </div>
                
              </div>
              
              <div class="col-3 cardCenter  p-4">
                <div class="card pt-3 t-center hintergrundHell" style="width: 22rem;">                  
                  <div class="card-body p-4">
                    <p class="cardHeader">Software</p>
                    <p class="card-text pt-5"><span class="psText">PILLARSOFT</span> <br/> erstellt Ihnen die Software, 
                      welche Sie für die optimale Organisation Ihres Unternehmens brauchen. <br/> <br/> Direkt mit Ihnen zusammen</p>
                      <p class=" pt-2" >
                        <a href="#Softwareloesungen" class="btn btn-outline-danger" tabindex="-1" role="button"
                        aria-disabled="true">Softwareentwicklung</a>
                    </p>
                  </div>
                </div>                
              </div>
              
              <div class="col-3 cardCenter  p-4">
                <div class="card pt-3 t-center hintergrundHell" style="width: 22rem;">                  
                  <div class="card-body p-4">
                    <p class="cardHeader">Webshops</p>
                    <p class="card-text pt-5"><span class="psText">PILLARSOFT</span> <br/> hilft Ihnen bei der Auswahl, Gestaltung, Umsetzung und Einrichtung Ihres Webshops. <br/> <br/> Direkt mit Ihnen zusammen</p>
                    <p class=" pt-2" >
                      <a href="#WEBSHOPS" class="btn btn-outline-danger" tabindex="-1" role="button"
                  aria-disabled="true">Onlineshop</a>
                    </p>
                  </div>
                </div>                
              </div>
            </div>
            <div class="row p-5 justify-content-center">
              <div class="col-3 cardCenter  p-4">
                <div class="card pt-3 t-center hintergrundHell" style="width: 22rem;">
                  
                  <div class="card-body p-4">
                    <p class="cardHeader">Beratung</p>
                    <p class="card-text pt-5"><span class="psText">PILLARSOFT</span> <br/>berät Sie bei Ihnen Vorort. In persönlichen Gesprächen erfragen wir Ihre Aufgabenstellung <br/> <br/> Direkt mit Ihnen zusammen</p>
                    <p class=" pt-2" >
                      <button
                      class="btn btn-outline-danger"
                       @click="$router.push('/Kontakt')">Kontakt</button>
                    </p>
                  </div>
                </div>
                
              </div>
              
              <div class="col-3 cardCenter  p-4">
                <div class="card pt-3 t-center hintergrundHell" style="width: 22rem;">                  
                  <div class="card-body p-4">
                    <p class="cardHeader">Support</p>
                    <p class="card-text pt-5"><span class="psText">PILLARSOFT</span> 
                      <br/> hat immer einen persönlichen Ansprechpartner für Sie. Auch bei Ihnen Vorort. <br/> <br/>Service ist uns besonders wichtig </p>
                      <p class=" pt-2" >
                        <button
                      class="btn btn-outline-danger"
                       @click="$router.push('/Kontakt')">Kontakt</button>
                    </p>
                  </div>
                </div>                
              </div>
              
              <div class="col-3 cardCenter  p-4">
                <div class="card pt-3 t-center hintergrundHell" style="width: 22rem;">                  
                  <div class="card-body p-4">
                    <p class="cardHeader">Projekte</p>
                    <p class="card-text pt-5"><span class="psText">PILLARSOFT</span> <br/> erarbeitet mit Ihnen zusammen den gesammten Projektablauf. Zu jeder Zeit können Sie Einfluß auf den weiteren Ablauf nehmen. </p>
                    <p class=" pt-2" >
                      <button
                      class="btn btn-outline-danger"
                       @click="$router.push('/Kontakt')">Kontakt</button>
                      
                    </p>
                  </div>
                </div>                
              </div>
            </div>
            
          </div>
          
        </div>
        <div
          id="stickybar"
          class="hidden-xs wow slideInRight animated"
          data-wow-delay="2s"
        >
          <ul>
            <li>
              <a
                href="kontakt/"
                class=""
                style="width: 70px; height: 70px; padding-top: 10px"
              >
                <i class="fa fa-phone fa-2x" style="font-size: 1.3em"></i>
                <span style="height: 70px; padding-top: 10px; margin-top: -10px"
                  >+49 36741 586899</span
                >
              </a>
            </li>
            <li>
              <a
                href="mailto: info@pillarsoft.de"
                class=""
                style="width: 70px; height: 70px; padding-top: 10px"
                ><i class="far fa-envelope-open fa-2x" style="font-size: 1.3em"></i>
                <span style="height: 70px; padding-top: 10px; margin-top: -10px">
                  info@pillarsoft.de</span
                ></a
              >
            </li>
          </ul>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script>
import Layout from "@/layouts/Layout";
//import carouselHeader from "../components/carouselHeader.vue";
import axios from "axios";

export default {
  name: "Start",

  data() {
    return {
      windowHeight: window.innerHeight,
      windowWight: window.innerWidth,
      newSite: null,
      mail: {
        telefon1: "",
        email1: "",
        datenschutz1: "",
        telefon2: "",
        email2: "",
        datenschutz2: "",
        telefon3: "",
        email3: "",
        datenschutz3: "",
        gesendet: "",
      },
      background: {
        telefon1: "",
        email1: "",
        datenschutz1: "",
        telefon2: "",
        email2: "",
        datenschutz2: "",
        telefon3: "",
        email3: "",
        datenschutz3: "",
      },
    };
  },
  components: {
    Layout,
    //  carouselHeader,
  },
  methods: {
    sendMail() {
      console.log("error.response");

      const axiosConfig = {
        headers: {
          Accept: "text/plain",
          "Content-Type": "text/plain",
        },
      };
      const payload = {
        empfaenger: "info@pillarsoft.de",
        emailWeb: this.mail.email1,
        telefonWeb: this.mail.telefon1,
        datenschutzWeb: this.mail.datenschutz1,
        emailShop: this.mail.email2,
        telefonShop: this.mail.telefon2,
        datenschutzShop: this.mail.datenschutz2,
        emailSoftware: this.mail.email3,
        telefonSoftware: this.mail.telefon3,
        datenschutzSoftware: this.mail.datenschutz3,
      };
      let mail = this.mail;
      let background = this.background;
      axios
        .post("https://pillar.de/mailrelay/post_attachment.php", payload, axiosConfig)
        .then((response) => {
          console.log("success", response.data);
          mail.gesendet = "Ihre Kontaktdaten wurden erfolgreich versendet";

          mail.email1 = "";
          mail.email2 = "";
          mail.email3 = "";
          background.email1 = "";
          background.email2 = "";
          background.email3 = "";
          mail.telefon1 = "";
          mail.telefon2 = "";
          mail.telefon3 = "";
          background.telefon1 = "";
          background.telefon2 = "";
          background.telefon3 = "";
          mail.datenschutz1 = false;
          mail.datenschutz3 = false;
          mail.datenschutz2 = false;
          background.datenschutz1 = "";
          background.datenschutz2 = "";
          background.datenschutz3 = "";
        })
        .catch((error) => {
          console.log(error.response);
          mail.gesendet =
            "Ihre Kontaktdaten konnten leider nicht gesendet werden. Bitte rufen Sie uns an.";
        });
    },
    check1() {
      let cl = true;
      if (this.mail.email1 == "" && this.mail.telefon1 == "") {
        cl = true;
      } else {
        if (this.mail.datenschutz1 == true) {
          cl = false;
        }
      }
      return cl;
    },
    check2() {
      let cl = true;
      if (this.mail.email2 == "" && this.mail.telefon2 == "") {
        cl = true;
      } else {
        if (this.mail.datenschutz2 == true) {
          cl = false;
        }
      }
      return cl;
    },
    check3() {
      let cl = true;
      if (this.mail.email3 == "" && this.mail.telefon3 == "") {
        cl = true;
      } else {
        if (this.mail.datenschutz3 == true) {
          cl = false;
        }
      }
      return cl;
    },
  },
  computed: {},
  mounted() {
    window.onresize = () => {
      this.windowHeight = window.innerHeight;
    };
  },
};
</script>

<style scoped></style>
